<template>
  <div></div>
</template>
<script>
export default {
  methods: {
    checkLogin() {
      // Bujaschool 로그인 체크 후 없으면 로그인페이지로 보내기
      // if (this.$store.getters['user/isLogin']) this.$router.push('/user')
      // else this.$router.push('/login')
      if (!this.$store.getters['user/isLogin']) this.$router.push('/login')
    }
  },
  created() {
    this.checkLogin()
  }
}
</script>
